import React, { Fragment } from "react";
import { SearchIcon } from '../../../assets';

export default function SearchSection({searchValue, handleSearch}) {

  return(
    <Fragment>
      <div className='rounded-lg border-2 bg-white border-gray-200 py-2 px-3.5'>
        <div className='flex items-center'>
          <SearchIcon />
          <input type="text" className="ml-1.5 focus:outline-none w-full bg-transparent" placeholder="Search" value={searchValue} onChange={(e) => handleSearch(e?.target?.value)}></input>
        </div>
      </div>
    </Fragment>
  )
}