import React, { Fragment, useState, useEffect } from "react";
import { ChevronDownIcon } from '@heroicons/react/outline'
import { chekLocationNameCount } from '../../../../../subRequests/helpers/helpers';

export default function CategoryDisabledFilter(props) {
  const { selectedCategories=[], categoriesList=[], setShowFilterPopup } = props
  const [ categoryIds, setCategoryIds ] = useState(categoriesList.map((category) => category.id) || [])

  useEffect(() => {
    setCategoryIds(categoriesList.map((category) => category.id))
  }, [categoriesList])

  const checkAllCategoriesSelected = () => {
    return categoryIds.every(v => selectedCategories.includes(v)) && categoryIds?.length > 0
  }

  const categoryNames = () => {
    let categoryList = categoriesList.filter((category) => selectedCategories.includes(category.id))
    let catNames = []
    let ctr = 0
    for(let i = 0; i < categoryList?.length; i++){
      if(ctr < 2){
        catNames.push(categoryList[i].name)
        ctr++
      }else{
        break;
      }
    }
    return chekLocationNameCount(catNames.join(", "))
  }

  return (
    <Fragment>
      <div className="w-full sm:w-56">
        <button className={`flex justify-center w-full pl-3 pr-2 py-2.5 bg-white border-gray-200 rounded-lg text-sm text-gray-900 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 border-2 border-gray-900 font-medium`} onClick={(e) => setShowFilterPopup(true)}>
          {(checkAllCategoriesSelected()) ?
            <p className='text-gray-900'>All Categories</p>
          :
            <React.Fragment>
              {(selectedCategories?.length === 1) && 
                <p className = "text-gray-900 truncate max-wc-175">{'Category: ' + categoryNames()}</p>
              }
              {(selectedCategories?.length > 1) && 
                <div className = "flex text-gray-900 items-center">
                  <p className="mr-1">{'Category: '}</p>
                  <div className={`text-gray-900 truncate max-wc-90`}>
                    {categoryNames()}
                  </div>
                  {(selectedCategories?.length - categoryNames()?.split(',')?.length) > 0 && <p className="text-xs">+{selectedCategories?.length - categoryNames()?.split(',')?.length}</p>}
                </div>
              }
            </React.Fragment>
          }
          <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5 shrink-0" aria-hidden="true" />
        </button>
      </div>
    </Fragment>
  )
}