import React, { Fragment, useState, useEffect, useRef } from "react";
import { chekLocationNameCount } from '../../../../subRequests/helpers/helpers';
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'

export default function CategoryFilter(props) {
  const { selectedCategories, handleSelectedCategories, categoriesList, categoryIds } = props
  const menuButtonRef = useRef(null);
  const [ selectedCategoryIds, setSelectedCategoryIds ] = useState(selectedCategories || [])
  const [ categories, setCategories ] = useState(categoriesList || [])
  const [ isOpen, setIsOpen ] = useState(false);

  useEffect(() => {
    setCategories(categoriesList)
  }, [categoriesList])

  useEffect(() => {
    if(isOpen) return;
    if(selectedCategoryIds?.length > 0 && categoryIds?.length > 0 && categoryIds?.length !== selectedCategoryIds?.length){
      $('.category_drop-down_button').addClass('filter-apply-border')
    }else {
      $('.category_drop-down_button').removeClass('filter-apply-border')
    }
  }, [selectedCategoryIds])

  const setRef = node => {
    if (node) {
      setIsOpen(true)
      $('.category_drop-down_button').addClass('filter-apply-border')
    }else if (node === null) {
      setIsOpen(false)
      if(!(selectedCategoryIds?.length > 0 && categoryIds?.length > 0 && categoryIds?.length !== selectedCategoryIds?.length)) $('.category_drop-down_button').removeClass('filter-apply-border')
    }
  };

  const cancelCategoryFilter = () => {
    menuButtonRef.current.click()
  }

  const checkAllCategoriesSelected = () => {
    return categoryIds.every(v => selectedCategoryIds.includes(v)) && categoryIds?.length > 0
  }

  const handleSelectAllCategories = (e) => {
    let newVal = e.target.checked ? categoryIds : []
    setSelectedCategoryIds(newVal)
    handleSelectedCategories(newVal)
  }

  const handleSelectCategory = (e) => {
    let newSelectedCategoryIds = []
    if (e.target.checked){
      newSelectedCategoryIds = [...selectedCategoryIds, parseInt(e.target.value)]
    }else{
      newSelectedCategoryIds = selectedCategoryIds.filter(item => item !== parseInt(e.target.value))
    }
    setSelectedCategoryIds(newSelectedCategoryIds)
    handleSelectedCategories(newSelectedCategoryIds)
  }

  const categoryNames = () => {
    let categoryList = categories.filter((category) => selectedCategoryIds.includes(category.id))
    let catNames = []
    let ctr = 0
    for(let i = 0; i < categoryList?.length; i++){
      if(ctr < 2){
        catNames.push(categoryList[i].name)
        ctr++
      }else{
        break;
      }
    }
    return chekLocationNameCount(catNames.join(", "))
  }

  return (
    <Fragment>
      <div className="relative">
        <Menu as="div" className="relative block text-left">
          <div>
            <Menu.Button className={`flex justify-center w-full rounded-lg pl-3 pr-2 py-2.5 bg-white text-sm text-gray-900 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 category_drop-down_button border-2 border-gray-200 need-border font-medium`} ref={menuButtonRef}>
              {selectedCategoryIds?.length === 0 ?
                <p>Select categories</p>
              :
              (checkAllCategoriesSelected()) ?
                <p className='text-gray-900'>All categories selected</p>
              :
                <React.Fragment>
                  {(selectedCategoryIds?.length === 1) && 
                    <p className = "text-gray-900">{categoryNames()}</p>
                  }
                  {(selectedCategoryIds?.length > 1) && 
                    <div className = "flex text-gray-900 items-center">
                      <div className={`text-gray-900 truncate max-wc-153`}>
                        {categoryNames()}
                      </div>
                      {(selectedCategoryIds?.length - categoryNames()?.split(',')?.length) > 0 && <p className="text-xs">+{selectedCategoryIds?.length - categoryNames()?.split(',')?.length}</p>}
                    </div>
                  }
                </React.Fragment>
              } 
              <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items ref={setRef} className="origin-top-right z-40 right-0 w-full rounded-lg bg-white ring-2 ring-gray-500 focus:outline-none mt-2 absolute">
              <div className="h-60 max-h-60 overflow-y-auto py-2">
                <div className="flex items-center justify-between px-5 py-2 mb-1">
                  <label className="inline-flex items-center text-sm">
                    <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-2" checked = {checkAllCategoriesSelected()} onChange = {(e) => handleSelectAllCategories(e)}/>
                    Select all
                  </label>
                  <a onClick={() => cancelCategoryFilter()} className='custom-red text-sm cursor-pointer'>Cancel</a>
                </div>
                <div className="">
                  {categories.map((category, i) =>
                    <div className="py-1" key = {i}>
                      <label className="flex items-center px-5 text-gray-900 text-sm mb-1">
                        <input type="checkbox" className={`w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1`} id={category.id} name="category" onChange={(e) => handleSelectCategory(e, category)} value={category.id} checked={selectedCategoryIds?.includes(category.id)}/>
                        <label className={`ml-1.5 text-sm font-medium text-gray-500`} htmlFor={category.id}>{category?.name}</label> 
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </Fragment>
  )
}