import React from "react";

export default function ExpiringTileIcon({classNames='wc-30 hc-30', strokeColor='#C2410C'}) {

  return(
    <svg className={classNames} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.25 27.5H23.75" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.25 2.5H23.75" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M21.25 27.5V22.285C21.2499 21.622 20.9864 20.9862 20.5175 20.5175L15 15L9.4825 20.5175C9.01363 20.9862 8.75014 21.622 8.75 22.285V27.5" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.75 2.5V7.715C8.75014 8.37799 9.01363 9.01377 9.4825 9.4825L15 15L20.5175 9.4825C20.9864 9.01377 21.2499 8.37799 21.25 7.715V2.5" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}