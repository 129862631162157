import React, { Fragment } from "react";
import { ChevronDownIcon } from '@heroicons/react/outline'

export default function StatusDisabledFilter(props) {
  const { selectedStatus, setShowFilterPopup } = props
  const statusList  = [
    {value: 'submitted', label: 'Submitted'},
    {value: 'not_submitted', label: 'Not Submitted'},
    {value: 'expired', label: 'Expired'},
    {value: 'expiring', label: 'Expiring'}
  ]

  const checkTypeName = (status) => {
    return statusList.filter((item) => status.includes(item.value)).map((item) => item.label).join(', ')
  }

  return(
    <Fragment>
      <div className="w-full sm:w-56">
        <button className={`flex justify-center w-full pl-3 pr-2 py-2.5 bg-white border-gray-200 rounded-lg text-sm text-gray-900 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 border-2 border-gray-900 font-medium`} onClick={(e) => setShowFilterPopup(true)}>
          {selectedStatus.length === statusList?.length ?
            "All Statuses"
          :
            <p className = "text-gray-900 truncate">{'Status: '+checkTypeName(selectedStatus)}</p>
          }
          <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5 shrink-0" aria-hidden="true" />
        </button>
      </div>
    </Fragment>
  )
}