import React from "react";

export default function ClearButtonIcon({classNames='wc-14 hc-14', strokeColor='white'}) {

  return(
    <svg className={classNames} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_497_6636)">
        <path d="M6.99935 12.8333C10.221 12.8333 12.8327 10.2216 12.8327 6.99996C12.8327 3.7783 10.221 1.16663 6.99935 1.16663C3.77769 1.16663 1.16602 3.7783 1.16602 6.99996C1.16602 10.2216 3.77769 12.8333 6.99935 12.8333Z" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.75 5.25L5.25 8.75" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5.25 5.25L8.75 8.75" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
      <clipPath id="clip0_497_6636">
        <rect className={classNames}/>
      </clipPath>
      </defs>
    </svg>
  )
}