import React, { Fragment, useEffect, useState, useCallback, useRef } from "react";
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { SearchIcon, LoadingIcon } from '../../../../../subRequests/assets';
import { axiosGetRequest, chekLocationNameCount } from '../../../../../subRequests/helpers/helpers'
import { debounce } from "lodash";

export default function InstructorFilter(props){
  const { selectedInstructors, handleSelectInstructors } = props
  const menuButtonRef = useRef(null);
  const [ allInstructors, setAllInstructors ] = useState([])
  const [ allInstToShow, setAllInstToShow ] = useState([])
  const [ searchValue, setSearchValue ] = useState('')
  const pluckSelectedInstIds = (selectedInts) => {
    return(selectedInts?.map((user) => user?.id))
  }
  const [ selectedInstHash, setSelectedInstHash ] = useState(selectedInstructors || [])
  const [ selectedInst, setSelectedInst ] = useState(pluckSelectedInstIds(selectedInstructors || []))
  const [ showLoading, setShowLoading ] = useState(false);
  
  useEffect(() => {
    setSelectedInstHash(selectedInstructors || [])
    setSelectedInst(pluckSelectedInstIds(selectedInstructors || []))
    setSearchValue('')
  }, [selectedInstructors])

  const handleInstructorSearch = (e) => {
    let val = e.target.value
    if (e.key === ' ') {
      e.preventDefault();
      val += ' ';
    }
    setSearchValue(val)
    searchInstructors(val)
  }

  const searchInstructors = useCallback(
    debounce(val => {
      if(val.trim() == '') {
        setAllInstToShow([]);
        setAllInstructors([])
      }else{
        handleSearchInstructors(val.trim())
      }
    }, 800),
    [selectedInst]
  );

  const handleSearchInstructors = (text) => {
    let url = `${window._sub_board_url}/api/v3/sub_overview/all_requests_filters?instructors_search=${text}`
    setShowLoading(true)
    axiosGetRequest(url).then((result) => {
      setShowLoading(false)
      if(result.status == 200){
        setAllInstructors(result?.instructors);
        setAllInstToShow(result?.instructors.filter((user) => !selectedInst.includes(user?.id)));
        $('infiniti-scroll-instructorspecial').scrollTop(0);
      }
    })
  }

  const selectInstructors = (e, user) => {
    let instId = parseInt(user?.id)
    var __FOUND = selectedInst.includes(instId)
    if(e.target.checked){
      if(!__FOUND){
        let newUserIds = [...selectedInst, instId]
        setSelectedInst(newUserIds)
        let newUser = {
          id: instId,
          full_name: user?.full_name
        }
        let newUsers = [...selectedInstHash, newUser]
        setSelectedInstHash(newUsers)
        handleSelectInstructors(newUsers)
        fillUnmatchedUsersInList(newUserIds)
      }
    }else{
      if(__FOUND){
        let newUserIds = selectedInst.filter((userId) => userId !== instId)
        setSelectedInst(newUserIds)
        let newUsers = selectedInstHash.filter((usr) => usr?.id !== instId)
        setSelectedInstHash(newUsers)
        handleSelectInstructors(newUsers)
        fillUnmatchedUsersInList(newUserIds)
      }
    }
  }

  const fillUnmatchedUsersInList = (users) => {
    if(users?.length > 0){
      let newUsers = allInstructors.filter((user) => !users.includes(user?.id))
      setAllInstToShow(newUsers)
    }else{
      setAllInstToShow(allInstructors)
    }
  }

  const instructorNames = () => {
    let users = selectedInstHash
    let userNames = []
    let ctr = 0
    for(let i = 0; i < users?.length; i++){
      if(ctr < 2){
        userNames.push(users[i].full_name)
        ctr++
      }else{
        break;
      }
    }
    return chekLocationNameCount(userNames.join(", "))
  }

  const processFillBackFilter = () => {
    setAllInstToShow([])
    setAllInstructors([])
    setSearchValue('')
  }

  useEffect(() => {
    if(selectedInstructors?.length > 0){
      $('.instructor_drop-down_button').addClass('filter-apply-border')
    }else {
      $('.instructor_drop-down_button').removeClass('filter-apply-border')
    }
  }, [selectedInstructors])

  const setInstrDropDownRef = (node) => {
    if(node){
      $('.instructor_drop-down_button').addClass('filter-apply-border')
    }else if (node === null) {
      if(selectedInstructors?.length == 0) $('.instructor_drop-down_button').removeClass('filter-apply-border')
      processFillBackFilter()
    }
  }

  return(
    <Fragment>
      <div className="relative">
        <Menu as="div" className="relative block text-left">
          <div>
            <Menu.Button className={`flex justify-center w-full pl-3 pr-2 py-2.5 bg-white border-gray-200 rounded-lg text-sm text-gray-900 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 instructor_drop-down_button border-2 need-border font-medium`} ref={menuButtonRef}>
              {selectedInstHash?.length == 0 && <p className = "text-gray-900">All Staff</p>
              }
              {selectedInstHash?.length > 0 && 
                <React.Fragment>
                  {(selectedInstHash?.length === 1) && 
                    <p className = "text-gray-900">{instructorNames()}</p>
                  }
                  {(selectedInst?.length > 1) && 
                    <div className = "flex text-gray-900 items-center">
                      <div className={`text-gray-900 truncate max-wc-153`}>
                        {instructorNames()}
                      </div>
                      {(selectedInstHash?.length - instructorNames()?.split(',')?.length) > 0 && <p className="text-xs">+{selectedInstHash?.length - instructorNames()?.split(',')?.length}</p>}
                    </div>
                  }
                </React.Fragment>
              }
              <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            // show={true}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items ref={setInstrDropDownRef} className="origin-top-right z-40 right-0 w-full rounded-lg bg-white ring-2 ring-gray-500 focus:outline-none mt-2 absolute">
              <div id={'infiniti-scroll-instructorspecial'} className="h-60 max-h-60 overflow-y-auto py-2">
                <div className='bg-white rounded-2xl'>
                  <div className='bg-gray-custom-50 rounded-2xl py-1.5 px-3.5 mx-5 my-1'>
                    <div className='flex items-center'>
                      <SearchIcon classNames={'h-5 w-5 text-gray-400'}/>
                      <input type="text" className="ml-1.5 focus:outline-none w-full bg-transparent" placeholder="Search" value={searchValue} onChange={(e) => handleInstructorSearch(e)} onKeyDown={handleInstructorSearch}></input>
                    </div>
                  </div>
                </div>
                {selectedInstHash?.length > 0 &&
                  <div className=''>  
                    {selectedInstHash.map((user, i) => 
                      <div className="py-1" key = {i}>
                        <label className="flex px-5 text-gray-900 text-sm mb-2">
                          <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-2 mt-0.5 cursor-pointer" onChange={(e) => selectInstructors(e, user)} value={user?.id} checked={selectedInst.includes(user?.id)}/>
                          <div className='flex items-center gap-2 cursor-pointer'>{user?.full_name}</div>
                        </label> 
                      </div>
                    )}
                    <div className="px-5 mb-1">
                      <hr />
                    </div>
                  </div>
                }
                <div className=''>  
                  {allInstToShow.map((user, i) => 
                    <div className="py-1" key = {i}>
                      <label className="flex px-5 text-gray-900 text-sm mb-2">
                        <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-2 mt-0.5 cursor-pointer" onChange={(e) => selectInstructors(e, user)} value={user?.id} checked={selectedInst.includes(user?.id)}/>
                        <div className='flex items-center gap-2 cursor-pointer'>{user?.full_name}</div>
                      </label> 
                    </div>
                  )}
                  {allInstToShow?.length == 0 && ((selectedInstHash?.length == 0) || (selectedInstHash?.length > 0 && searchValue.trim() == '')) && !showLoading &&
                    <div className={`flex text-sm text-gray-700 items-center text-center justify-between px-5 py-2 ${(allInstToShow?.length == 0 && selectedInstHash?.length == 0) ? 'h-44' : ''}`}>
                      Search for an instructor's name to filter
                    </div>
                  }
                  {showLoading &&
                    <div className={`flex items-center justify-center px-5 py-2 ${(allInstToShow?.length == 0 && selectedInstHash?.length == 0) ? 'h-44' : ''}`}>
                      <LoadingIcon classNames={'animate-spin h-4 w-4 text-black'}/>
                    </div>
                  }
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </Fragment>
  )
}