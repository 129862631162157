import React from "react";

export default function ExpiredTileIcon({classNames='wc-30 hc-30', strokeColor='#B91C1C'}) {
  return(
    <svg className={classNames} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.75 5H6.25C4.86929 5 3.75 6.11929 3.75 7.5V25C3.75 26.3807 4.86929 27.5 6.25 27.5H23.75C25.1307 27.5 26.25 26.3807 26.25 25V7.5C26.25 6.11929 25.1307 5 23.75 5Z" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M20 2.5V7.5" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10 2.5V7.5" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3.75 12.5H26.25" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.5 17.5L17.5 22.5" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M17.5 17.5L12.5 22.5" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}