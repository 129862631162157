import React, { Component } from 'react'
import { getSudomain, setTitle, getHeaders } from './../../helpers'
import Cookies from 'js-cookie';

export default class FisikalDetails extends Component {
  constructor(props){
    super(props);
    this.state = {
      business: props.business,
      show: false,
      alert_type: '',
      alert_message: '',
      clubs: props.business?.fisikal_club_ids?.join(', ') || '',
      client_id: props.business?.fisikal_client_id || '',
      client_secret: props.business?.fisikal_client_secret || '',
      subdomain: props.business?.fisikal_subdomain || '',
      userName: props.business?.fisikal_user_name || '',
      password: props.business?.fisikal_password || '',
      dataSaving: false
    }
  }

  componentDidMount = () => {
    setTitle('fisikal')
  }

  save = (e) => {
    e.preventDefault()
    this.setState({dataSaving: true})
    const requestOptions = {
      method: 'POST',
      headers: getHeaders(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()),
      body: JSON.stringify({ client_id: this.state.client_id.toString(), client_secret: this.state.client_secret.toString(), user_name: this.state.userName.toString(), password: this.state.password.toString(), subdomain: this.state.subdomain.toString() })
    };
    fetch('/api/v3/fisikal/save_fisikal_details', requestOptions)
    .then(response => response.json())
    .then(result => {
      if(result.status === 200){
        this.setState({
          alert_message: result.success,
          alert_type: 'success',
          show: true,
          client_id: result.data.client_id,
          client_secret: result.data.client_secret,
          userName: result.data.user_name,
          password: result.data.password,
          subdomain: result.data.subdomain,
          clubs: result.data.club_id,
          dataSaving: false
        })
        setTimeout(function(){ window.location.reload() }, 1000);
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true,
          dataSaving: false
        })
      }
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },1000)
      });
    })
  }

  syncDetails = (e, type) => {
    e.preventDefault()
    fetch(`/api/v3/fisikal/sync_details?sync_type=${type}`,{
      method: "GET",
      headers: getHeaders(Cookies.get(`currentUser.${getSudomain()}`), getSudomain())
    })
    .then(response => response.json())
    .then(result => {
      if(result.status === 200){
        this.setState({
          alert_message: result?.message,
          alert_type: 'success',
          show: true
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
      }
      window.setTimeout(() => {
        this.setState({show: false});
        window.location.reload();
      }, 1000)
    })
  }

  submitChecks = () => {
    if (this.state.client_id === '' || this.state.client_secret === '' || this.state.userName === '' || this.state.password === '' || this.state.subdomain === ''){
      return true
    }else{
      return false
    }
  }

  render() {
    const { business, show, alert_type, alert_message, clubs, client_id, client_secret, subdomain, userName, password, dataSaving } = this.state

    return (
      <React.Fragment>
        {show &&
          <React.Fragment>
            {alert_type === 'success' &&
              <div className="w-3/5 flex items-center bg-green-50 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-400 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-green-800 font-medium">
                  {alert_message}
                </div>
                <a className="ml-auto" onClick={(e) => this.setState({show: false})}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }
            {alert_type === 'danger' &&
              <div className="w-3/5 flex items-center bg-red-100 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-300 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-red-700 font-medium">
                  {alert_message}
                </div>
                <a className="ml-auto" onClick={(e) => this.setState({show: false})}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }
          </React.Fragment>
        }
        <div className='w-full min-h-screen bg-gray-50'>
          <div className='h-full'>
            <div className='max-w-8xl mx-auto h-full'>
              <div className='flex gap-4 justify-between'>
                <div className='left-0'>
                  <h3 className='flex gap-3 text-lg'>
                    Add Fisikal Details
                  </h3>
                </div>
                <div className='right-0'>
                </div>
              </div>
              <div className='mt-3 overflow-x-auto sm:-mx-6 lg:-mx-8 mb-4'>
                <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
                  <div className='shadow border overflow-hidden border-b border-gray-200 sm:rounded-lg'>
                    <div className="bg-white px-3 py-2">
                      <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start pt-5 py-2'>
                        <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Client ID*
                        </label>
                        <div className='mt-1 sm:mt-0 sm:col-span-2'>
                          <input type="text" className="lg:w-80 border rounded-md px-2 py-1.5 w-full shadow-sm text-sm h-10" placeholder='Eg:- 698963dfc0efecb3bc52a22395d88111' onChange={(e) => this.setState({client_id: e.target.value})} defaultValue={client_id}/>
                        </div>
                      </div>
                      <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start py-2'>
                        <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Client Secret*
                        </label>
                        <div className='mt-1 sm:mt-0 sm:col-span-2'>
                          <input type="text" className="lg:w-80 border rounded-md px-2 py-1.5 w-full shadow-sm text-sm h-10" placeholder='Eg:- 054bb5dad84337bc87744d2cd55f7c11' onChange={(e) => this.setState({client_secret: e.target.value})} defaultValue={client_secret}/>
                        </div>
                      </div>
                      <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start py-2'>
                        <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Subdomain*
                        </label>
                        <div className='mt-1 sm:mt-0 sm:col-span-2'>
                          <input type="text" className="lg:w-80 border rounded-md px-2 py-1.5 w-full shadow-sm text-sm h-10" placeholder='Enter Subdomain' onChange={(e) => this.setState({subdomain: e.target.value})} defaultValue={subdomain}/>
                        </div>
                      </div>
                      <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start py-2'>
                        <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">User Name*
                        </label>
                        <div className='mt-1 sm:mt-0 sm:col-span-2'>
                          <input type="text" className="lg:w-80 border rounded-md px-2 py-1.5 w-full shadow-sm text-sm h-10 " placeholder='Enter User Name' onChange={(e) => this.setState({userName: e.target.value})} defaultValue={userName}/>
                        </div>
                      </div>
                      <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start py-2'>
                        <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Password*
                        </label>
                        <div className='mt-1 sm:mt-0 sm:col-span-2'>
                          <input type="text" className="lg:w-80 border rounded-md px-2 py-1.5 w-full shadow-sm text-sm h-10" placeholder='Enter Password' onChange={(e) => this.setState({password: e.target.value})} defaultValue={password}/>
                        </div>
                      </div>
                      <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start py-2 pb-5'>
                        <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Club ID(s)
                        </label>
                        <div className='mt-1 sm:mt-0 sm:col-span-2'>
                          <input type="text" className={`lg:w-80 border rounded-md px-2 py-1.5 w-full shadow-sm cursor-not-allowed text-sm h-10`} defaultValue={clubs} disabled={true} />
                        </div>
                      </div>
                      <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start py-2'>
                        <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2"></label>
                        <div className='mt-1 sm:mt-0 sm:col-span-2'>
                          <div>
                            <button className={`bg-dark-blue text-white inline-block px-6 py-2 ml-auto border shadow rounded-md relative ${(this.submitChecks() || dataSaving) ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`} onClick={(e) => this.save(e)} disabled={this.submitChecks() || dataSaving}>
                              {dataSaving &&
                                <div className="flex items-center left-1 top-3 justify-center absolute">
                                  <svg className="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                  </svg>
                                </div>
                              }
                              Save Fisikal Details
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {clubs != "" &&
                    <div className='flex gap-3 mt-4'>
                      <button className={`inline-block px-4 py-2 border shadow rounded-md ${business?.show_sync_users ? 'bg-dark-blue text-white cursor-pointer' : 'cursor-not-allowed opacity-50 bg-dark-blue text-white'}`} disabled={!business?.show_sync_users} onClick={(e) => this.syncDetails(e, 'users')}>
                        Sync Users
                      </button>
                      <button className={`inline-block px-4 py-2 border shadow rounded-md ${business?.show_sync_classes ? 'bg-dark-blue text-white cursor-pointer' : 'cursor-not-allowed opacity-50 bg-dark-blue text-white'}`} disabled={!business?.show_sync_classes} onClick={(e) => this.syncDetails(e, 'classes')}>
                        Sync Classes
                      </button>
                      <button className={`inline-block px-4 py-2 border shadow rounded-md ${business?.show_sync_locations ? 'bg-dark-blue text-white cursor-pointer' : 'cursor-not-allowed opacity-50 bg-dark-blue text-white'}`} disabled={!business?.show_sync_locations} onClick={(e) => this.syncDetails(e, 'locations')}>
                        Sync Locations
                      </button>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}