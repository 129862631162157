import React, { Fragment } from "react";
import ClearButtonIcon from '../../../../assets/clearButtonIcon';

export default function ClearButton({handleClearFilter}) {
  return(
    <Fragment>
      <button className={`h-11 rounded-lg flex items-center px-3 gap-2 bg-gray-900 border-2 border-gray-900 relative text-white text-sm font-semibold`} onClick={() => handleClearFilter()}>
        <ClearButtonIcon />
        Clear Filters
      </button>
    </Fragment>
  )
}