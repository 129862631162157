import React from "react";

export default function FilterButtonIcon({classNames='w-5 h-5'}) {
  return (
    <svg className={classNames} viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_497_5534" fill="white">
        <path d="M16.4588 6.9306H12.5838C12.3129 6.9306 12.0977 6.71532 12.0977 6.44449C12.0977 6.17365 12.3129 5.95837 12.5838 5.95837H16.4588C16.7296 5.95837 16.9449 6.17365 16.9449 6.44449C16.9449 6.71532 16.7296 6.9306 16.4588 6.9306Z"/>
      </mask>
      <path d="M16.4588 6.9306H12.5838C12.3129 6.9306 12.0977 6.71532 12.0977 6.44449C12.0977 6.17365 12.3129 5.95837 12.5838 5.95837H16.4588C16.7296 5.95837 16.9449 6.17365 16.9449 6.44449C16.9449 6.71532 16.7296 6.9306 16.4588 6.9306Z" fill="black"/>
      <path d="M16.4588 5.9306H12.5838V7.9306H16.4588V5.9306ZM12.5838 5.9306C12.8652 5.9306 13.0977 6.16303 13.0977 6.44449H11.0977C11.0977 7.2676 11.7606 7.9306 12.5838 7.9306V5.9306ZM13.0977 6.44449C13.0977 6.72594 12.8652 6.95837 12.5838 6.95837V4.95837C11.7606 4.95837 11.0977 5.62137 11.0977 6.44449H13.0977ZM12.5838 6.95837H16.4588V4.95837H12.5838V6.95837ZM16.4588 6.95837C16.1773 6.95837 15.9449 6.72594 15.9449 6.44449H17.9449C17.9449 5.62137 17.2819 4.95837 16.4588 4.95837V6.95837ZM15.9449 6.44449C15.9449 6.16303 16.1773 5.9306 16.4588 5.9306V7.9306C17.2819 7.9306 17.9449 7.26761 17.9449 6.44449H15.9449Z" fill="#1F2937" mask="url(#path-1-inside-1_497_5534)"/>
      <mask id="path-3-inside-2_497_5534" fill="white">
        <path d="M6.12511 6.9306H3.54178C3.27094 6.9306 3.05566 6.71532 3.05566 6.44449C3.05566 6.17365 3.27094 5.95837 3.54178 5.95837H6.12511C6.39594 5.95837 6.61122 6.17365 6.61122 6.44449C6.61122 6.71532 6.389 6.9306 6.12511 6.9306Z"/>
      </mask>
      <path d="M6.12511 6.9306H3.54178C3.27094 6.9306 3.05566 6.71532 3.05566 6.44449C3.05566 6.17365 3.27094 5.95837 3.54178 5.95837H6.12511C6.39594 5.95837 6.61122 6.17365 6.61122 6.44449C6.61122 6.71532 6.389 6.9306 6.12511 6.9306Z" fill="black"/>
      <path d="M6.12511 5.9306H3.54178V7.9306H6.12511V5.9306ZM3.54178 5.9306C3.82323 5.9306 4.05566 6.16303 4.05566 6.44449H2.05566C2.05566 7.2676 2.71866 7.9306 3.54178 7.9306V5.9306ZM4.05566 6.44449C4.05566 6.72594 3.82323 6.95837 3.54178 6.95837V4.95837C2.71866 4.95837 2.05566 5.62137 2.05566 6.44449H4.05566ZM3.54178 6.95837H6.12511V4.95837H3.54178V6.95837ZM6.12511 6.95837C5.84366 6.95837 5.61122 6.72594 5.61122 6.44449H7.61122C7.61122 5.62137 6.94823 4.95837 6.12511 4.95837V6.95837ZM5.61122 6.44449C5.61122 6.15377 5.84602 5.9306 6.12511 5.9306V7.9306C6.93197 7.9306 7.61122 7.27687 7.61122 6.44449H5.61122Z" fill="#1F2937" mask="url(#path-3-inside-2_497_5534)"/>
      <path d="M8.7079 9.18753C10.2228 9.18753 11.451 7.95942 11.451 6.44447C11.451 4.92952 10.2228 3.70142 8.7079 3.70142C7.19295 3.70142 5.96484 4.92952 5.96484 6.44447C5.96484 7.95942 7.19295 9.18753 8.7079 9.18753Z" fill="black"/>
      <path d="M16.4581 14.0347H13.8748C13.604 14.0347 13.3887 13.8194 13.3887 13.5486C13.3887 13.2778 13.604 13.0625 13.8748 13.0625H16.4581C16.7289 13.0625 16.9442 13.2778 16.9442 13.5486C16.9442 13.8194 16.7289 14.0347 16.4581 14.0347Z" fill="black"/>
      <path d="M7.41678 14.0347H3.54178C3.27094 14.0347 3.05566 13.8194 3.05566 13.5486C3.05566 13.2778 3.27094 13.0625 3.54178 13.0625H7.41678C7.68761 13.0625 7.90289 13.2778 7.90289 13.5486C7.90289 13.8194 7.68066 14.0347 7.41678 14.0347Z" fill="black"/>
      <path d="M11.2919 16.2986C12.8068 16.2986 14.0349 15.0705 14.0349 13.5556C14.0349 12.0406 12.8068 10.8125 11.2919 10.8125C9.77694 10.8125 8.54883 12.0406 8.54883 13.5556C8.54883 15.0705 9.77694 16.2986 11.2919 16.2986Z" fill="black"/>
    </svg>
  )
}