import React, { Fragment } from "react";
import { ChevronDownIcon } from '@heroicons/react/outline'
import { chekLocationNameCount } from '../../../../../subRequests/helpers/helpers';

export default function InstructorDisabledFilter(props) {
  const { selectedInstructors, setShowFilterPopup } = props

  const instructorNames = () => {
      let users = selectedInstructors
      let userNames = []
      let ctr = 0
      for(let i = 0; i < users?.length; i++){
        if(ctr < 2){
          userNames.push(users[i].full_name)
          ctr++
        }else{
          break;
        }
      }
      return chekLocationNameCount(userNames.join(", "))
    }

  return(
    <Fragment>
      <div className="w-full sm:w-56">
        <button className={`flex justify-center w-full pl-3 pr-2 py-2.5 bg-white border-gray-200 rounded-lg text-sm text-gray-900 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 border-2 border-gray-900 font-medium`} onClick={(e) => setShowFilterPopup(true)}>
          {selectedInstructors?.length > 0 && 
            <Fragment>
              {(selectedInstructors?.length === 1) && 
                <p className = "text-gray-900 truncate max-wc-175">{'Staff: ' + instructorNames()}</p>
              }
              {(selectedInstructors?.length > 1) && 
                <div className = "flex text-gray-900 items-center">
                  <p className="mr-1">{'Staff: '}</p>
                  <div className={`text-gray-900 truncate max-wc-130`}>
                    {instructorNames()}
                  </div>
                  {(selectedInstructors?.length - instructorNames()?.split(',')?.length) > 0 && <p className="text-xs">+{selectedInstructors?.length - instructorNames()?.split(',')?.length}</p>}
                </div>
              }
            </Fragment>
          }
          <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </Fragment>
  )
}