import React, { Fragment } from "react";
import FilterButtonIcon from '../../../../assets/filterButtonIcon';

export default function FilterButton(props) {
  const { showFilterPopup, setShowFilterPopup } = props
  return (
    <Fragment>
      <button className={`w-11 h-11 rounded-lg flex items-center justify-center bg-white border-2 border-gray-${showFilterPopup ? '900' : '200'} relative`} onClick={() => setShowFilterPopup(true)}>
        <FilterButtonIcon classNames={`w-5 h-5 opacity-${showFilterPopup ? '100' : '50'} hover:opacity-100`}/>
      </button>
    </Fragment>
  )
}