import React, { Fragment } from "react";
import { ChevronDownIcon } from '@heroicons/react/outline'
import { chekLocationNameCount, seprateOutLocations } from '../../../../../subRequests/helpers/helpers';

export default function LocationDisabledFilter(props) {
  const { regionLocationsList, selectedFilterLocations, setShowFilterPopup } = props
  const locationList = seprateOutLocations(regionLocationsList) || []

  const locationName = () => {
    let locations = locationList
    let selected_locations = selectedFilterLocations
    let locationNames = []
    let ctr = 0
    for(let i = 0; i < locations?.length; i++){
      if(selected_locations.includes(locations[i].id)){
        if(ctr < 2){
          locationNames.push(locations[i].name)
          ctr++
        }
        else{
          break;
        }
      }
    }
    return chekLocationNameCount(locationNames.join(", "))
  }

  return(
    <Fragment>
      <div className="w-full sm:w-56">
        <button className={`flex justify-center w-full pl-3 pr-2 py-2.5 bg-white border-gray-200 rounded-lg text-sm text-gray-900 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 border-2 border-gray-900 font-medium`} onClick={(e) => setShowFilterPopup(true)}>
          {selectedFilterLocations?.length === locationList?.length && <p className = "text-gray-900">All Locations</p>
          }
          {selectedFilterLocations?.length < locationList?.length && 
            <React.Fragment>
              {(selectedFilterLocations?.length === 1 && selectedFilterLocations?.length !== 0) && 
                <p className = "text-gray-900 truncate max-wc-175">{'Location: ' + locationName()}</p>
              }
              {(selectedFilterLocations?.length > 1 && selectedFilterLocations?.length < locationList?.length) && 
                <div className = "flex text-gray-900 items-center">
                  <p className="mr-1">{'Location: '}</p>
                  <div className={`text-gray-900 truncate max-wc-90`}>{locationName()}</div>
                  {(selectedFilterLocations?.length - locationName()?.split(',')?.length) > 0 && <p>+{selectedFilterLocations?.length - locationName()?.split(',')?.length}</p>}
                </div>
              }
            </React.Fragment>
          }
          {(selectedFilterLocations?.length === 0  && selectedFilterLocations?.length !== locationList?.length) && <p>Select location(s)</p>}
          <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5 shrink-0" aria-hidden="true" />
        </button>
      </div>
    </Fragment>
  )
}