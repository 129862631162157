import React, { Fragment, useState, useEffect } from "react";
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { hideItems } from '../../../../subRequests/helpers/helpers'
import { statusColours } from '../../../helpers';

export default function StatusFilter(props) {
  const { selectedStatus, handleSelectedStatus, statusFilterCounts, statusList, allStatusValues } = props
  const [ statusValues, setStatusValues ] = useState(selectedStatus)

  useEffect(() => {
    setStatusValues(selectedStatus)
  }, [selectedStatus])

  const checkTypeName = (status) => {
    return statusList.filter((item) => status.includes(item.value)).map((item) => item.label).join(', ')
  }

  const handleChangeStatus = (e, status) => {
    var __FOUND = statusValues.includes(status)
    if(e.target.checked){
      if(!__FOUND){
        let newStatus = [...statusValues, status]
        setStatusValues(newStatus)
        handleSelectedStatus(newStatus)
      }
    }else{
      if(__FOUND){
        let newStatus = statusValues.filter((tag) => tag !== status)  
        setStatusValues(newStatus)
        handleSelectedStatus(newStatus)
      }      
    }
  }

  const cancelStatusFilter = () => {
    hideItems('statusspecial')
  }

  const handleSelectAll = () => {
    if(checkSelectAll()){
      setStatusValues([])
      handleSelectedStatus([])
    }else{
      setStatusValues(allStatusValues)
      handleSelectedStatus(allStatusValues)
    }
  }

  const checkSelectAll = () => {
    return statusValues.length === allStatusValues.length
  }

  useEffect(() => {
    if(statusValues?.length > 0 && statusList?.length !== statusValues?.length){
      $('.statusspecial_items_button').addClass('filter-apply-border')
    }else {
      $('.statusspecial_items_button').removeClass('filter-apply-border')
    }
  }, [statusValues])

  const checkTypeAndShowBg = (status) => {
    return statusColours[status];
  }

  const checkTypeCounts = (status) => {
    let count = statusFilterCounts[status]
    return count > 0 ? ` (${count})` : '';
  }

  return(
    <Fragment>
      <div className="relative filters_box"> 
        <div className="hidden my-status-special-div" onClick={() => {}}></div>
        <Menu as="div" className="relative block text-left">
          <div>
            <Menu.Button className="flex justify-center w-full rounded-lg pl-3 pr-2 py-2.5 bg-white text-sm text-gray-900 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 statusspecial_items_button border-2 border-gray-200 need-border font-medium">
              {statusValues.length === 0 ? 
                "Select a status"
              : 
                (statusValues.length === statusList?.length ?
                  "All Statuses"
                :
                  <p className = "text-gray-900 truncate max-w-195">{checkTypeName(statusValues)}</p>
                )
              }
              <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            show={true}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className="origin-top-right z-40 right-0 w-full rounded-lg bg-white ring-2 ring-gray-500 focus:outline-none mt-2 hidden statusspecial_items filter_items absolute">
              <div className="py-2">
                <div className='flex items-center justify-between px-4 py-2 mb-1'>
                  <label className="inline-flex items-center text-sm">
                    <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-2" checked = {checkSelectAll()} onChange = {(e) => handleSelectAll(e)}/>
                    Select all
                  </label>
                  <a onClick={() => cancelStatusFilter()} className='custom-red text-sm cursor-pointer'>Cancel</a>
                </div>
                <div>
                  {statusList.map((type, i) => 
                    <Menu.Item key = {i}>
                      {({ active }) => (
                        <label className="flex items-center px-4 text-gray-900 text-sm mb-2">
                          <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 cursor-pointer mr-2" onChange={(e) => handleChangeStatus(e, type?.value)} value={type?.value} checked={statusValues.includes(type?.value)}/>
                          <div className={`flex items-center gap-2 cursor-pointer ${checkTypeAndShowBg(type?.value)} px-3 py-0.5 rounded-2xl`}>
                            {`${type?.label}${checkTypeCounts(type?.value)}`}
                          </div>
                        </label>
                      )}
                    </Menu.Item>
                  )}
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </Fragment>
  )
}