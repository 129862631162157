import React from "react";

export default function AssignedTileIcon({classNames='wc-30 hc-30', strokeColor='#334155'}) {
  return(
    <svg className={classNames} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25 24.375V25C25 25.663 24.7366 26.2989 24.2678 26.7678C23.7989 27.2366 23.163 27.5 22.5 27.5H7.5C6.83696 27.5 6.20107 27.2366 5.73223 26.7678C5.26339 26.2989 5 25.663 5 25V5C5 4.33696 5.26339 3.70107 5.73223 3.23223C6.20107 2.76339 6.83696 2.5 7.5 2.5H18.125L22.5 6.875" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10 22.5H11.25" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M23.025 12.0125C23.2688 11.7688 23.5582 11.5754 23.8767 11.4435C24.1952 11.3116 24.5365 11.2437 24.8813 11.2437C25.226 11.2437 25.5673 11.3116 25.8858 11.4435C26.2043 11.5754 26.4937 11.7688 26.7375 12.0125C26.9813 12.2563 27.1746 12.5457 27.3066 12.8642C27.4385 13.1827 27.5064 13.524 27.5064 13.8688C27.5064 14.2135 27.4385 14.5549 27.3066 14.8734C27.1746 15.1919 26.9813 15.4813 26.7375 15.725L21.1875 21.25L16.25 22.5L17.4875 17.5625L23.025 12.0125Z" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}